<template>
  <div class="error-container d-flex justify-center align-center">
    <div class="content d-flex flex-column">
      <div class="tt-text-headline-2">
        {{ $t('errors.404.title') }}
      </div>
      <div class="tt-text-body-1 mt-3">
        {{ $t('errors.404.text') }}
      </div>
      <TTBtn
        class="mt-8"
        :to="{ name }"
        large
        width="180"
        data-test="tt-btn"
        data-test-value="dashboard-btn"
      >
        {{ $t('errors.404.btn') }}
      </TTBtn>
    </div>
    <VImg
      :src="require('@/assets/404-robot.png')"
      max-height="496"
      max-width="376"
      contain
      data-test-value="404-img"
    />
  </div>
</template>

<script>
export default {
  name: 'Error404',
  inject: ['Names'],
  props: {
    moduleName: {
      type: String,
      default: 'core',
    },
  },
  computed: {
    name() {
      switch (this.moduleName) {
        case 'learning':
          return this.Names.R_LEARNING_HOME;
        case 'goals':
          return this.Names.R_GOALS_HOME;
        case 'engagement':
          return this.Names.R_ENGAGEMENT_HOME;
        case 'onboarding':
          return this.Names.R_ONBOARDING_HOME;
        default:
          return this.Names.R_CORE_HOME;
      }
    },
  },
};
</script>

<style scoped>
.error-container {
  margin-top: 100px;
}
.content {
  margin-right: 120px;
}
</style>
